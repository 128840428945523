<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <step-status :active="2"></step-status>
        
        <el-alert
            title="关于通票"
            type="warning"
            description="如果需要售卖通票(多个场次均可使用),请联系秀动客服,经营平台暂不支持创建通票,敬请谅解。"
            close-text="知道了"
            show-icon>
        </el-alert>
        <el-form
            :model="formData"
            ref="formData"
            label-position="top"
            v-loading="dataLoading"
        >
            <activity-session v-if="reFresh" :formData.sync="formData.sessions" :editType="2"></activity-session>

            <div v-if="formMessage"  class="formMessage MT10">
                <el-alert type="error" :closable="false" title="提示"><div v-html="formMessage"></div></el-alert>
            </div>

            <div class="page-group-box MT10">
                <el-form-item class="form-btn-box">
                    <el-button @click="submitForm(1)">上一步</el-button>
                    <el-button
                        @click="$refs['newSession'].show()"
                        :loading="btnLoading"
                        >新增场次</el-button
                    >
                    <el-button
                        type="primary"
                        @click="submitForm(2)"
                        :loading="btnLoading"
                        >下一步</el-button
                    >
                    <span class="color-danger ML20"><i class="el-icon-warning"></i> 对于购票用户提交的退票申请，请及时在秀动APP专业版处理，以免影响后续结算。</span>
                </el-form-item>
            </div>

        </el-form>
        <!-- 新增场次 -->
        <new-session ref="newSession" :formData.sync="formData" :activityId="id"></new-session>

    </div>
</template>

<script>
import StepStatus from "./components/step-status";
import ActivitySession from './components/activity-session';
import NewSession from './components/new-session';


const defaultData = {
    sessionName:'', 
    startTime:'',
    endTime:'',
    // datetimerange:[],
    artistNames:'',
    ticketPrices:[],
    status:1,
    artistList: [],
	guestsList:[],
	performers:[],
	guests:[],
	singleName:'',
	setTime:false,
}
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{
        StepStatus,
        ActivitySession,
        NewSession
    },
    data(){
        return{
            redirect: this.$route.query.redirect || `/manage/activity/edit/step1/${this.id}`,
            publishType: this.$route.query.publishType || 1, //1、新建，2、编辑
            dataLoading:false,
            btnLoading: false,
            formData:{
                sessions:[{
                    ...defaultData,
                    ticketPrices:[],
                    activityId:this.id,
					
                }]
            },
            reFresh:false,
            formMessage: ''
        }
    },
    created(){
        if( this.publishType == 2 ){
            this.getDataDetail();
        }else{
            this.reFresh = true;
        }
    },
    methods:{

        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/activity/getSessionsById",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        let _formData = {
                            sessions:result && result.length > 0 ? [...result] : [{...defaultData,activityId:this.id}],
                        };
                        _formData.sessions.map(item => {
                            // item.datetimerange = item.startTime ? [item.startTime,item.endTime] : [];
                            item.ticketPrices = item.ticketPrices || [];
                            item.artistList = item.performers ? [...item.performers] : [];
							item.guestsList = item.guests ? [...item.guests] : [];
							item.guests = item.guests || [];
							item.setTime = false;
                            if( item.performers && item.performers.length > 0 ){
                                item.performers.forEach(items => {
                                    var datetimerangeUser = ["",""];
                                    if(items.startTime && items.endTime){
                                        datetimerangeUser = [items.startTime, items.endTime];
                                    }
                                    items.datetimerangeUser = datetimerangeUser;
							    })
                            }
							
                        })
                        this.formData = _formData;
                        this.reFresh = true;
                    } else {
                        self.$message.error(msg || "获取演出场次失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出场次失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },

        //表单提交
        submitForm(type) {           
            var self = this;
			// if( this.$utils.compareDate(this.formData.transferStartTime, this.formData.transferEndTime) ){
			//     return this.$message.error("转赠开始时间不能大于等于结束时间");
			// }
			
            try{
            	this.submitData = this.formData;
            	// console.log(this.submitData)
            	this.submitData.sessions.forEach( item => {
            		var newUser = []
            		item.performers.forEach(v => {
            			newUser.push({
            				id: v.id,
            				name:v.name,
            				startTime: v.datetimerangeUser && v.datetimerangeUser!=null && v.datetimerangeUser[0] != undefined ? v.datetimerangeUser[0]:"",
            				endTime: v.datetimerangeUser && v.datetimerangeUser!=null && v.datetimerangeUser[1] != undefined ? v.datetimerangeUser[1]:"",
            				startUnix: v.datetimerangeUser && v.datetimerangeUser!=null && v.datetimerangeUser[0] != undefined ? new Date(v.datetimerangeUser[0].replace(/-/g,"/")).getTime():0,
            				endUnix: v.datetimerangeUser && v.datetimerangeUser!=null && v.datetimerangeUser[1] != undefined ? new Date(v.datetimerangeUser[1].replace(/-/g,"/")).getTime():0,
            			})
            		})
            		
            		//console.log(newUser)
            		var timeNull = 0
            		for( var i1 = 0; i1 < newUser.length; i1 ++ ) {
            			if(newUser[i1].startTime == "" || newUser[i1].endTime == ""){
            				++timeNull
            			}
            		}
            		if(timeNull > 0 && timeNull != newUser.length && item.status !=  -1){
            			
            			throw new Error(item.sessionName+"还有"+timeNull+"个艺人出场时间段未配置，请补全后提交");
            			return false;
            		}
            		if(timeNull == 0){
            			for( i1 = 0; i1 < newUser.length; i1 ++ ) {
            				var v1 = newUser[i1]
            				for( var i2 = 0; i2 < newUser.length; i2 ++ ) {
            					if(i1 == i2){
            						continue;
            					}
            					var v2 = newUser[i2]
            					if(v1.startUnix <= v2.endUnix && v1.endUnix >= v2.startUnix){
            						
            						throw new Error(item.sessionName+'的艺人演出时间段和其他艺人重叠，请重新选择');
            						return false; 
            					}
            				}
            			}
            		}
            	   
            	   
            	})
            	
            }catch(err){
               return this.$message.error(err.message);
            }
			// console.log(this.formData.sessions)
			//return
            self.$refs["formData"].validate(async (valid,label) => {
                const _flag = type == 1 ? true : valid;
                if (_flag) {

                    this.formMessage = '';

                    const _os = this.formData.sessions , _newSessions = this.formData.sessions.filter(v => v.status !=  -1);

                    const _names = _newSessions.map( item => item.sessionName || item.sessionNamePlaceholder);
                    if( new Set(_names).size !== _names.length ){
                        return this.$message.error('场次名称有重复,请重新输入');
                    }
                    
                    const _url =  "/activity/publishSession"
                    
                    // _os.map( item => {
                    //     item.startTime = item.datetimerange[0];
                    //     item.endTime = item.datetimerange[1];
                    // })
                    let timeFlg = 0;    
                    _os.map((v,i) => {
						if( v.status !=  -1 ){
                            if( this.$utils.compareDate( v.startTime, v.endTime ) ){
                                this.formMessage += `<div>场次${i+1}: 演出开始时间必须小于演出结束时间</div>`;
                            }
                            if( v.canRefundTime && this.$utils.compareDate2( v.canRefundTime, v.startTime ) ){
                                this.formMessage += `<div>场次${i+1}: 申请退票截止时间不能晚于演出开始时间</div>`;
                            }
                            
                            v.ticketPrices.map( t => {
                                if( new Date(t.startTime.replace(/-/g,"/")).getTime() < new Date().getTime() ){
                                    timeFlg += 1;
                                }
                            })
                        }
                        v.sessionName = v.sessionName || v.sessionNamePlaceholder
                    })

                    if(this.formMessage && this.formMessage != '') return;

                    if( timeFlg > 0 && type == 2){
                        const flag = await this.$confirm('有票品的开售时间早于当前时间，这会导致演出通过审核后立即开票，是否继续下一步', '提示', {
                            confirmButtonText: '继续下一步',
                            cancelButtonText: '返回修改',
                            showClose: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            center: true,
                            type: 'warning',
                        }).catch(() => 'cancel');

                        if (flag === 'cancel') {
                            return;
                        }
                    }


                    this.btnLoading = true;

                    this.$request({
                        url: _url,
                        data: _os,
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                //self.goBack()
                                if(type == 1){
                                    self.goBack()
                                }else{
                                    this.$router.push({
                                        path: `/manage/activity/edit/step3/${this.id}`,
                                        query: {
                                            redirect: `/manage/activity/edit/step2/${this.id}?publishType=2`,
                                        },
                                    });
                                }
                            } else {
                                self.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            self.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                } else {
                    
                    let keys = Object.keys(label)
                    const formMessage = label[keys[0]][0].message;
                    this.formMessage = formMessage;
                    return false;//this.$message.error(formMessage);
                }
            });
        },
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
    }
}
</script>


<style lang="scss" scoped>
.el-card {
    margin-bottom: 10px;
    border:0;
    ::v-deep .el-card__header{
        h2{
            line-height: 28px;
        }
    }
}
.add-btn-bar{
    border:1px $--color-theme dashed;
    text-align: center;
    height: 42px;
    line-height: 42px;
    // margin: 20px 0;
    cursor: pointer;
    color: $--color-theme;
    font-weight: 700;
    font-size: 14px;
}
.el-alert{
    margin-bottom: 20px;
}
.dialog-content{
    text-align: center;
}
</style>